import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import TechStackSlider from '../Components/TechStackSlider';
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Blockchain development company</title>
        <meta property="title" content="Rarible clone script" />
        <meta
          name="description"
          content="Block Intelligence is a premier NFT Marketplace development company that helps you launch your NFT Marketplace like Rarible with Rarible clone script."
        />
        <meta
          name="keywords"
          content="p2p crypto exchange development company,  p2p cryptocurrency exchange development company, p2p crypto exchange development, p2p crypto exchange software development, p2p exchange development, p2p cryptocurrency exchange development, p2p exchange development company, p2p cryptocurrency exchange software, crypto p2p exchange application development, p2p crypto exchange development solutions, p2p crypto exchange development services"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="cryptoex_head">
        <section className="cryptoex">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp h1tag">
                  <span> Blockchain Development company </span>
                </h1>
                <p className="wow fadeInUp   mt-4">
                   Blockchain Development company refers to the creation of
                  blockchain solutions that operate on top of existing
                  blockchain networks, enhancing their functionality and
                  scalability. This approach allows developers to build
                  decentralized applications (dApps) that can leverage the
                  security and infrastructure of established blockchains while
                  introducing new features and capabilities. Layer solutions can
                  significantly improve transaction speeds, reduce costs, and
                  provide greater flexibility for developers and users alike. By
                  utilizing Layer 2 technologies, such as state channels and
                  sidechains, businesses can create innovative applications that
                  meet the growing demands of the digital economy. This
                  development process involves careful planning, robust
                  architecture design, and thorough testing to ensure seamless
                  integration with the underlying blockchain.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <div className=" mt-5">
                  <img
                    src={require("../Images/cryptoex.webp")}
                    width="90%"
                    className="mx-auto d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="py-5">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2 className=" ">Our Layer development service</h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 px-3">
                <a href="/Layer-0-Blockchain-Development" className="text-decoration-none">
                  <div className="cryptocards">
                    <h4 className="p-3 text-center text-white">
                      Layer 0 blockchain development
                    </h4>
                    <img
                      src={require("../Images/cencard.webp")}
                      className="mx-auto d-block"
                      width="100%"
                      alt="p2p crypto exchange development company"
                    />
                    <p className="px-3 text-center">
                      Develop a cutting-edge cryptocurrency exchange platform that
                      facilitates the excellent exchange of cryptocurrencies under
                      the supervision of a central governing authority.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 px-3">
              <a href="/Layer-1-Blockchain-Development" className="text-decoration-none">
                <div className="cryptocards">
                  <h4 className="p-3 text-center text-white">
                  Layer 1 blockchain development
                  </h4>
                  <img
                    src={require("../Images/cencard.webp")}
                    className="mx-auto d-block"
                    width="100%"
                    alt="p2p crypto exchange development company"
                  />

                  <p className="px-3 text-center">
                    Enhance the versatility of your exchange platform by
                    incorporating a hybrid model that combines the features of
                    both centralized and decentralized exchanges.
                  </p>
                </div>
                </a>
              </div>
              <div className="col-lg-4 px-3">
                <div className="cryptocards">
                    <a href="/Layer-2-Blockchain-Development" className="text-decoration-none">
                  <h4 className="p-3 text-center text-white">
                  Layer 2 blockchain development
                  </h4>
                  <img
                    src={require("../Images/cencard.webp")}
                    className="mx-auto d-block"
                    width="100%"
                    alt="p2p crypto exchange development company"
                  />

                  <p className="px-3 text-center">
                    Develop an innovative cryptocurrency exchange platform that
                    supports decentralization and enables smooth digital
                    currency trading.
                  </p>
                  </a>
                </div>
                
              </div>
              

             

             

            
            
             

             
            </div>
          </div>
        </section>

      

       

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
           <TechStackSlider/>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
