import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import TechStackSlider from '../Components/TechStackSlider';

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);const [tokenBalance, setTokenBalance] = useState(0); // New state for token balance

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  

  return (
    <>
      <Helmet>
        <title>ERC20 Token Development Company</title>
        <meta property="title" content="Crypto Token Development Company" />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/crypto-token-development"
        />
        <meta
          name="description"
          content="Develop your Crypto token with Block Intelligence, A leading crypto token development company  helps you create and launch your own custom tokens on the blockchain of your choice."
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="token_head">
        <section className="p2p tokens ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2 mt-5">
                <h1 className="wow fadeInUp h1tag mt-5">
                  <span>ERC20 Token Development Services</span> 
                </h1>
                <p className="wow fadeInUp mt-4">
                  Are you ready to launch your own ERC20 token on the Ethereum blockchain? Our expert team at Block Intelligence is here to guide you through the ERC20 token development process, ensuring a successful launch.
                  <br /> <br />
                  With our tailored solutions, you can achieve compliance and security while harnessing the full potential of blockchain technology.
                </p>
              </div>
              <div className="col-lg-6 mt-5">
                <img
                  src={require("../Images/tokenbg.webp")}
                  className="d-block mx-auto mt-5"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="whatokens">
          <div className="container-md whatoke">
            <div className="row mt-5">
              <div className="col-lg-6 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/tokensec2.webp")}
                  className="tokensimg d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-6 mt-5">
                <div className="p2pcontent">
                  <h2>
                    What is <span>ERC20 Token Creation</span> ?
                  </h2>
                  <p>
                  ERC20 token creation refers to the process of developing a digital token on the Ethereum blockchain that adheres to the ERC20 standard. This standard defines a set of rules and functions that a token must implement, allowing for interoperability with various wallets and decentralized applications (dApps). An ERC20 token typically includes functions for transferring tokens, checking balances, and approving third-party spending. By following this standard, developers can ensure that their tokens can be easily integrated into the Ethereum ecosystem, facilitating trading and usage across different platforms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
     
        <section className="token-keyfeatures">
          <div className="container">
            <div>
              <h2>
                Our <span>Token Development</span> Services
              </h2>
              <div className="row mt-5">
                <div class="col-lg-4 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-token">
                    <p class="keyfeature-card-tokentitle">Admin panel</p>
                    <p class="keyfeature-card-para text-dark">
                      {" "}
                      Our Admin panel is elegant with better UI, trading
                      functionalities, better transaction, and with other modern
                      features.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-token">
                    <p class="keyfeature-card-tokentitle">Trading options</p>
                    <p class="keyfeature-card-para text-dark">
                      We Provide a range of trading choices to your users. Our
                      platform supports a variety of trading preferences, from
                      limit orders to market orders.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-token">
                    <p class="keyfeature-card-tokentitle">Escrow System </p>
                    <p class="keyfeature-card-para text-dark">
                      {" "}
                      With an escrow security system, users can trade assets
                      securely. The transaction will be executed only when both
                      the parties obligations are met.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      

        <section className="Benfit-tokens">
          <div className="container">
            <h2>
              Advantages of <span>ERC20 Token Development</span>{" "}
            </h2>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-tokensecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/global-network.webp")}
                      className="benfit-img-p2p2"
                      alt="Global Accessibility"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Global Accessibility
                  </h5>
                  <p className="benfeature-card-p"> Crypto knows no borders.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-tokensecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/security-shield.webp")}
                      className="benfit-img-p2p2"
                      alt="Enhanced Privacy"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Enhanced Privacy</h5>
                  <p className="benfeature-card-p">
                    {" "}
                    Your transactions, your business.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-tokensecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/flexibility 1.webp")}
                      className="benfit-img-p2p2"
                      alt="Flexibility & Variety"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Flexibility & Variety
                  </h5>
                  <p className="benfeature-card-p">
                    Diverse crypto choices at your fingertips.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features-tokens">
          <div className="container">
            <div className="feature-content pb-3">
              <h2>
                {" "}
                Key Features of Our <span>ERC20 Token Development</span>{" "}
              </h2>
            </div>
            <div className="feature-card-row">
              <div className="row ">
                <div className="col-lg-4">
                  <div className="feature-card-tokens ">
                    <img
                      src={require("../Images/money-transfer.webp")}
                      className="feature-img-p2p"
                      alt="Escrow System"
                    />
                    <p className="feature-card-p2ptitle text-dark">
                      Increased Revenue Opportunities
                    </p>
                    <p className="keyfeature-card-para text-dark">
                      By creating crypto tokens, you can generate new revenue
                      streams and monetize your assets, products, or services,
                      by leveraging the power of blockchain and cryptocurrency
                      to enable value exchange, crowdfunding, and tokenization.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-card-tokens">
                    <img
                      src={require("../Images/third-party (1).webp")}
                      className="feature-img-p2p"
                      alt="Two Factor Authentication"
                    />
                    <p className="feature-card-p2ptitle text-dark">
                      Improved Customer Engagement
                    </p>
                    <p className="keyfeature-card-para text-dark">
                      By creating crypto tokens, you can offer your customers a
                      new and exciting way to engage with your brand, products,
                      or services, by providing them with incentives, rewards,
                      ownership, and participation in the blockchain ecosystem.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-card-tokens">
                    <img
                      src={require("../Images/liquidity2.webp")}
                      className="feature-img-p2p"
                      alt="KYC and AML Verification"
                    />
                    <p className="feature-card-p2ptitle text-dark">
                      Enhanced Liquidity
                    </p>
                    <p className="keyfeature-card-para text-dark">
                      By creating crypto tokens, you can increase the liquidity
                      and accessibility of your assets, products, or services,
                      by enabling peer-to-peer transactions, micropayments, and
                      digital economies on the blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="firstokens">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <img
                  src={require("../Images/tokencontact.webp")}
                  className="d-block mx-auto"
                  width="80%"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-6  mt-5">
                <h2 className="text-dark">
                  <span>Get in Touch</span> with Us Today
                </h2>

                <p className="text-dark">
                  If you're interested in our crypto token development services or want to explore how crypto tokens can enhance your business or customer engagement, please reach out to us today. We are eager to discuss your ERC20 token project and provide a complimentary consultation and quote for your development needs. We look forward to collaborating with you to create exceptional ERC20 tokens.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <TechStackSlider />
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
