import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import TechStackSlider from '../Components/TechStackSlider';

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [tokenName, setTokenName] = useState(""); // New state for token name
  const [tokenSymbol, setTokenSymbol] = useState(""); // New state for token symbol
  const [tokenBalance, setTokenBalance] = useState(0); // New state for token balance

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  // New function to create a BEP20 token
  const createToken = () => {
    // Logic to create a BEP20 token
    console.log(`Creating token: ${tokenName} (${tokenSymbol})`);
    // ... token creation logic ...
  };

  // New function to transfer tokens
  const transferTokens = (amount) => {
    // Logic to transfer tokens
    console.log(`Transferring ${amount} tokens`);
    // ... token transfer logic ...
  };

  // New function to check balance
  const checkBalance = () => {
    // Logic to check token balance
    console.log(`Checking balance for ${tokenName}`);
    // ... balance checking logic ...
  };

  return (
    <>
      <Helmet>
        <title>BEP20 Token Development Company</title>
        <meta property="title" content="Crypto Token Development Company" />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/crypto-token-development"
        />
        <meta
          name="description"
          content="Develop your Crypto token with Block Intelligence, A leading crypto token development company  helps you create and launch your own custom tokens on the blockchain of your choice."
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="token_head">
        <section className="p2p tokens ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2 mt-5">
                <h1 className="wow fadeInUp h1tag mt-5">
                  <span>BEP20 Token Development</span> 
                </h1>
                <p className="wow fadeInUp mt-4">
                  Are you looking to create your own BEP20 token on the Binance Smart Chain? Our team at Block Intelligence specializes in BEP20 token development, providing you with the tools and expertise to launch your token successfully.
                  <br /> <br />
                  With our comprehensive solutions, you can ensure compliance and security while leveraging the benefits of blockchain technology.
                </p>
              </div>
              <div className="col-lg-6 mt-5">
                <img
                  src={require("../Images/tokenbg.webp")}
                  className="d-block mx-auto mt-5"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="whatokens">
          <div className="container-md whatoke">
            <div className="row mt-5">
              <div className="col-lg-6 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/tokensec2.webp")}
                  className="tokensimg d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-6 mt-5">
                <div className="p2pcontent">
                  <h2>
                    What is <span>BEP20 Token Development</span> ?
                  </h2>
                  <p>
                    BEP20 token development is the process of creating tokens that adhere to the BEP20 standard on the Binance Smart Chain. These tokens can be used for various applications, including decentralized finance (DeFi), gaming, and more, while ensuring compatibility with the Binance ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="firstokens">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 ">
                <h2>
                  Why Choose Block Intelligence for{" "}
                  <span>BEP20 Token Development</span> Service?
                </h2>
                <p>
                  Block Intelligence is a leading BEP20 token development company. We offer secure, compliant, and user-friendly BEP20 token solutions. Our experienced developers are adept at creating high-performance BEP20 tokens that meet your business needs. Start your BEP20 project with us and take advantage of the growing Binance Smart Chain ecosystem.
                </p>
              </div>
              <div className="col-lg-5">
                <img
                  src={require("../Images/tokensec3.webp")}
                  className="d-block mx-auto"
                  width="70%"
                  alt="p2p cryptocurrency exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="token-keyfeatures">
          <div className="container">
            <div>
              <h2>
                Our <span>Token Development</span> Services
              </h2>
              <div className="row mt-5">
                <div class="col-lg-4 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-token">
                    <p class="keyfeature-card-tokentitle">Admin panel</p>
                    <p class="keyfeature-card-para text-dark">
                      {" "}
                      Our Admin panel is elegant with better UI, trading
                      functionalities, better transaction, and with other modern
                      features.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-token">
                    <p class="keyfeature-card-tokentitle">Trading options</p>
                    <p class="keyfeature-card-para text-dark">
                      We Provide a range of trading choices to your users. Our
                      platform supports a variety of trading preferences, from
                      limit orders to market orders.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-token">
                    <p class="keyfeature-card-tokentitle">Escrow System </p>
                    <p class="keyfeature-card-para text-dark">
                      {" "}
                      With an escrow security system, users can trade assets
                      securely. The transaction will be executed only when both
                      the parties obligations are met.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      

        <section className="Benfit-tokens">
          <div className="container">
            <h2>
              Benefits of <span>BEP20 Token Development</span>{" "}
            </h2>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-tokensecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/global-network.webp")}
                      className="benfit-img-p2p2"
                      alt="Global Accessibility"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Global Accessibility
                  </h5>
                  <p className="benfeature-card-p"> Crypto knows no borders.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-tokensecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/security-shield.webp")}
                      className="benfit-img-p2p2"
                      alt="Enhanced Privacy"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Enhanced Privacy</h5>
                  <p className="benfeature-card-p">
                    {" "}
                    Your transactions, your business.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-tokensecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/flexibility 1.webp")}
                      className="benfit-img-p2p2"
                      alt="Flexibility & Variety"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Flexibility & Variety
                  </h5>
                  <p className="benfeature-card-p">
                    Diverse crypto choices at your fingertips.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features-tokens">
          <div className="container">
            <div className="feature-content pb-3">
              <h2>
                {" "}
                Features of Our <span>BEP20 Token Development</span>{" "}
              </h2>
            </div>
            <div className="feature-card-row">
              <div className="row ">
                <div className="col-lg-4">
                  <div className="feature-card-tokens ">
                    <img
                      src={require("../Images/money-transfer.webp")}
                      className="feature-img-p2p"
                      alt="Escrow System"
                    />
                    <p className="feature-card-p2ptitle text-dark">
                      Increased Revenue Opportunities
                    </p>
                    <p className="keyfeature-card-para text-dark">
                      By creating crypto tokens, you can generate new revenue
                      streams and monetize your assets, products, or services,
                      by leveraging the power of blockchain and cryptocurrency
                      to enable value exchange, crowdfunding, and tokenization.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-card-tokens">
                    <img
                      src={require("../Images/third-party (1).webp")}
                      className="feature-img-p2p"
                      alt="Two Factor Authentication"
                    />
                    <p className="feature-card-p2ptitle text-dark">
                      Improved Customer Engagement
                    </p>
                    <p className="keyfeature-card-para text-dark">
                      By creating crypto tokens, you can offer your customers a
                      new and exciting way to engage with your brand, products,
                      or services, by providing them with incentives, rewards,
                      ownership, and participation in the blockchain ecosystem.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="feature-card-tokens">
                    <img
                      src={require("../Images/liquidity2.webp")}
                      className="feature-img-p2p"
                      alt="KYC and AML Verification"
                    />
                    <p className="feature-card-p2ptitle text-dark">
                      Enhanced Liquidity
                    </p>
                    <p className="keyfeature-card-para text-dark">
                      By creating crypto tokens, you can increase the liquidity
                      and accessibility of your assets, products, or services,
                      by enabling peer-to-peer transactions, micropayments, and
                      digital economies on the blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="firstokens">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <img
                  src={require("../Images/tokencontact.webp")}
                  className="d-block mx-auto"
                  width="80%"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-6  mt-5">
                <h2 className="text-dark">
                  <span>Contact Us</span> Today
                </h2>

                <p className="text-dark">
                  If you are interested in our crypto token development
                  services, or want to learn more about crypto tokens and how
                  they can benefit your business or customers, please contact us
                  today. We would love to hear from you and discuss your crypto
                  token project with you. We can provide you with a free
                  consultation and a quote for your crypto token development. We
                  look forward to working with you and creating amazing crypto
                  tokens for you.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <TechStackSlider/> 
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
